const TOGGLE_NAMES = ["DUMMY_TOGGLE", "EMERGENCY_DISABLE_OPEN_QUESTION_FEEDBACK"] as const;
const TOGGLES_ELEMENT_ID = "reptile-everglades-toggles";

export type ToggleName = typeof TOGGLE_NAMES[number];
export type ToggleStates = Record<ToggleName, boolean>;

export default class Toggles {
  private readonly toggles: ToggleStates;

  constructor() {
    const data = document.getElementById(TOGGLES_ELEMENT_ID)?.dataset;
    const state = data ? (toggleName: ToggleName) => asBoolean(data[toggleName.toLowerCase()]) : () => false;
    this.toggles = TOGGLE_NAMES.reduce((htmlToggles, toggleName) => {
      htmlToggles[toggleName] = state(toggleName);
      return htmlToggles;
    }, {} as Partial<ToggleStates>) as ToggleStates;
  }

  public isActive(toggle: ToggleName) {
    return this.toggles[toggle];
  }
}

function asBoolean(value?: string): boolean {
  return (value && JSON.parse(value)) || false;
}

export const toggles = new Toggles();
