import { EventLoaderEvents } from "@otto-ec/global-resources/event-loader";
import { eventQBus as eventQBusRaw } from "@otto-ec/global-resources/event-q-bus";
import { TilelistTopics } from "./Tilelist";
import { TrackingTopics } from "./Tracking";

type AssetsCounterTopic = {
  "assets.formular.counter": [string]; /*                                                      */
};

export type Topics = EventLoaderEvents &
  TilelistTopics &
  TrackingTopics &
  AssetsCounterTopic;

export const eventQBus = eventQBusRaw.with<Topics>();
